import Card from "../card";
import Dropdown from "../dropdown";
import Text from "../text";
import {
  InnerListWrapper,
  ItemCol,
  ItemsRow,
  ItemsWrapper,
  ListTitle,
  ListWrapper,
  ResultsLabel,
  SortCol,
  SortRow,
  SortingWrapper,
  TextWrapper,
} from "./styled";

type ListProps = {
  rows: "single" | "multiple";
  title: string;
  items: any[];
  filters?: any[];
  text?: string;
  single?: boolean;
  columns?: number;
};

type SortingProps = {
  count: number;
  filters?: any[];
};

const Sorting = ({ count, filters }: SortingProps) => {
  return (
    <SortingWrapper>
      <SortRow>
        <SortCol>
          <ResultsLabel>{count} results showing</ResultsLabel>
        </SortCol>
        {filters && filters?.length > 0 && (
          <SortCol xs={3} className="align-right">
            <Dropdown items={filters} title="Sort by" />
          </SortCol>
        )}
      </SortRow>
    </SortingWrapper>
  );
};

const List = ({
  rows,
  title,
  text,
  items,
  single,
  columns,
  filters,
}: ListProps) => {
  return (
    <ListWrapper>
      <InnerListWrapper>
        <ListTitle>{title}</ListTitle>
        <TextWrapper>{text && <Text>{text}</Text>}</TextWrapper>
        {rows === "multiple" && (
          <Sorting count={items.length} filters={filters} />
        )}
        <ItemsWrapper className={`${rows === "single" ? "single-row" : ""}`}>
          <ItemsRow>
            {items.map((item) => (
              <ItemCol
                xs={single ? 12 : 6}
                md={columns ? columns : 4}
                className={`${
                  !item?.title && !item?.description && "my-auto gallery"
                }`}
              >
                <Card
                  imageUrl={item?.imageUrl}
                  hoverUrl={item?.hoverUrl}
                  title={item?.title}
                  linkUrl={item?.link}
                  description={item?.description}
                />
              </ItemCol>
            ))}
          </ItemsRow>
        </ItemsWrapper>
      </InnerListWrapper>
    </ListWrapper>
  );
};

export default List;
