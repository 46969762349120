import Helmet from "react-helmet";

const Meta = () => {
  return (
    <Helmet>
      <title>ld2art - Privacy Policy</title>
      <meta name="thumbnail" content="URL/showcase.jpg" />
      <meta name="robots" content="index, follow" />
      <meta
        name="description"
        content="At ld2art, we respect your privacy and are committed to protecting any information you share with us. This Privacy Policy outlines our practices.."
      />
      <meta name="author" content="ld2art" />
      <meta name="keywords" content="" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content="URL/privacy-policy" />
      <meta name="twitter:title" content={`ld2art - Privacy Policy`} />
      <meta
        name="twitter:description"
        content="At ld2art, we respect your privacy and are committed to protecting any information you share with us. This Privacy Policy outlines our practices.."
      />
      <meta name="twitter:image" content="URL/showcase.jpg" />

      <meta property="og:url" content="URL/privacy-policy" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={`ld2art - Privacy Policy`} />
      <meta
        property="og:description"
        content="At ld2art, we respect your privacy and are committed to protecting any information you share with us. This Privacy Policy outlines our practices.."
      />
      <meta property="og:image" content="URL/showcase.jpg" />
      <link rel="canonical" href="URL/privacy-policy" />
    </Helmet>
  );
};

export default Meta;
