import Carousel from "react-bootstrap/Carousel";
import {
  InnerSliderWrapper,
  SliderWrapper,
  StyledCarousel,
  StyledImage,
  StyledText,
  StyledTitle,
} from "./styled";

type SliderProps = {
  items: any[];
};

const Slider = ({ items }: SliderProps) => {
  return (
    <SliderWrapper>
      <InnerSliderWrapper>
        <StyledCarousel>
          {items.map((item) => (
            <Carousel.Item>
              <StyledImage src={item?.imageUrl} alt={item?.altText} />
              {item?.caption && (
                <Carousel.Caption>
                  {item.caption.split("|")[0] && (
                    <StyledTitle>{item.caption.split("|")[0]}</StyledTitle>
                  )}
                  {item.caption.split("|")[1] && (
                    <StyledText>{item.caption.split("|")[1]}</StyledText>
                  )}
                </Carousel.Caption>
              )}
            </Carousel.Item>
          ))}
        </StyledCarousel>
      </InnerSliderWrapper>
    </SliderWrapper>
  );
};

export default Slider;
