import { useState } from "react";
import {
  InnerNavigationWrapper,
  NavDropdownItem,
  NavDropdownWrapper,
  NavLink,
  NavText,
  NavWrapper,
  NavbarBrand,
  NavbarCollapse,
  NavbarToggle,
  NavbarWrapper,
  NavigationWrapper,
  Toggle,
  ToggleIcon,
} from "./styled";
import menuIcon from "../../assets/img/menu-icon.svg";

const Navigation = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <NavigationWrapper>
      <InnerNavigationWrapper className="justify-content-end">
        <NavbarWrapper aria-label="" collapseOnSelect expand="lg">
          <NavLink to="/">
            <NavbarBrand>ld2art</NavbarBrand>
          </NavLink>

          <NavbarToggle aria-controls="responsive-navbar-nav">
            <Toggle onClick={() => setCollapsed(!collapsed)}>
              <ToggleIcon src={menuIcon} />
            </Toggle>
          </NavbarToggle>
          <NavbarCollapse id="responsive-navbar-nav">
            <NavWrapper className="ms-auto">
              <NavLink aria-label="Home" to="/">
                <NavText>Home</NavText>
              </NavLink>
              <NavDropdownWrapper title="Gallery" id="basic-nav-dropdown">
                <NavDropdownItem href="/gallery?sort=non-abstract">
                  Non-abstract
                </NavDropdownItem>
                <NavDropdownItem href="/gallery?sort=abstract">
                  Abstract
                </NavDropdownItem>
              </NavDropdownWrapper>
              <NavDropdownWrapper title="Earlier works" id="basic-nav-dropdown">
                <NavDropdownItem href="/earlier-works/photography">
                  Photography
                </NavDropdownItem>
                <NavDropdownItem href="/earlier-works/drawing-and-painting">
                  Drawing & painting
                </NavDropdownItem>
                <NavDropdownItem href="/earlier-works/film">
                  Film
                </NavDropdownItem>
              </NavDropdownWrapper>
              <NavLink aria-label="Contact" to="/contact">
                <NavText>Contact</NavText>
              </NavLink>
            </NavWrapper>
          </NavbarCollapse>
        </NavbarWrapper>
      </InnerNavigationWrapper>
    </NavigationWrapper>
  );
};

export default Navigation;
