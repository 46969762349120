import { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Navigation from "../../components/navigation";
import Overview from "./overview";
import useFetchArtworks from "../../hooks/useFetchArtworks";
import useFetchGalleryDetails from "../../hooks/useFetchGalleryDetails";
import Meta from "./meta";
import { useLocation } from "react-router-dom";

const Gallery = () => {
  const location = useLocation();

  const { fetchArtworks, artworks } = useFetchArtworks();
  const { details, fetchData } = useFetchGalleryDetails();
  const [filter, setFilter] = useState<"all" | "abstract" | "non-abstract">();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (filter) {
      fetchArtworks(filter);
    } else {
      const sortParam = searchParams.get("sort");
      if (sortParam === "abstract" || sortParam === "non-abstract") {
        setFilter(sortParam);
      } else {
        setFilter("all");
      }
    }
    fetchData();
  }, [fetchArtworks, fetchData, filter, location.search]);

  return (
    <>
      <Meta />
      <Navigation />
      <Overview artworks={artworks} details={details} setFilter={setFilter} />
      <Footer />
    </>
  );
};

export default Gallery;
