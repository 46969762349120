import { useCallback, useState } from "react";

const useFetchFrontpageDetails = () => {
  const [details, setDetails] = useState({
    slider: [] as any[],
    subtitle: "",
    features: [] as any[],
  });
  const fetchData = useCallback(async () => {
    try {
      const request = await fetch(
        "https://ld2art-backend-00333e89e8d8.herokuapp.com/api/frontpage?populate=*",
        { method: "GET" }
      );
      if (request.ok) {
        let images: any[] = [];
        let features: any[] = [];
        const { data } = await request.json();
        data?.attributes.Slider.data?.forEach((image: any) => {
          images.push({
            altText: image?.attributes?.alternativeText || null,
            imageUrl: `${image?.attributes?.url}`,
            caption: image?.attributes?.caption,
          });
        });
        features.push({
          imageUrl: data?.attributes?.FirstFeaturedImage?.data?.attributes?.url,
          altText:
            data?.attributes?.FirstFeaturedImage?.attributes?.alternativeText,
          link: data?.attributes?.FirstFeaturedLink,
          title: data?.attributes?.FirstFeaturedTitle,
        });
        features.push({
          imageUrl:
            data?.attributes?.SecondFeaturedImage?.data?.attributes?.url,
          altText:
            data?.attributes?.SecondFeaturedImage?.attributes?.alternativeText,
          link: data?.attributes?.SecondFeaturedLink,
          title: data?.attributes?.SecondFeaturedTitle,
        });
        features.push({
          imageUrl: data?.attributes?.ThirdFeaturedImage?.data?.attributes?.url,
          altText:
            data?.attributes?.ThirdFeaturedImage?.attributes?.alternativeText,
          link: data?.attributes?.ThirdFeaturedLink,
          title: data?.attributes?.ThirdFeaturedTitle,
        });
        setDetails({
          subtitle: data?.attributes?.Subtitle,
          slider: images,
          features: features,
        });
      }
    } catch (e: any) {
      throw new Error(e);
    }
  }, []);
  return { fetchData, details };
};

export default useFetchFrontpageDetails;
