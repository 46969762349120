import styled from "@emotion/styled";
import { Carousel } from "react-bootstrap";

export const SliderWrapper = styled.div`
  width: 100%;
`;

export const InnerSliderWrapper = styled.div`
  padding: 20px;
`;

export const StyledCarousel = styled(Carousel)``;

export const StyledImage = styled.img`
  width: 100%;
`;

export const StyledTitle = styled.h3`
  font-family: "Copperplate";
  font-size: 28px;
`;

export const StyledText = styled.p`
  font-family: "Times";
  font-size: 16px;
`;
