import Text from "../../../components/text";
import Title from "../../../components/title";
import {
  CoverThumbnail,
  DescriptionWrapper,
  DetailsWrapper,
  Field,
  InnerOverviewWrapper,
  OverviewWrapper,
} from "./styled";

type OverviewProps = {
  films: any;
};

const Overview = ({ films }: OverviewProps) => {
  if (!films[0]?.imageUrl) return null;

  const renderFilm = () => {
    const film = films[0];
    return film;
  };

  return (
    <OverviewWrapper>
      <InnerOverviewWrapper>
        <CoverThumbnail src={renderFilm()?.imageUrl} />
        <DetailsWrapper>
          <Title>{renderFilm()?.title}</Title>
          <DescriptionWrapper>
            {renderFilm()?.description?.map((block: any) => (
              <Field>
                {block?.children?.map((child: any) => (
                  <Text>{child.text}</Text>
                ))}
              </Field>
            ))}
          </DescriptionWrapper>
        </DetailsWrapper>
      </InnerOverviewWrapper>
    </OverviewWrapper>
  );
};

export default Overview;
