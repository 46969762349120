import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const CardWrapper = styled.div`
  border: 2px solid #000;
  width: 100%;
  margin: 20px 0px;
  padding: 10px;
  &.masonry-view {
    margin: 0px;
  }
`;

export const InnerCardWrapper = styled.div`
  padding: 20px;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const CardImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* Set the desired aspect ratio (e.g., 4:3) */
  overflow: hidden;

  @media (max-width: 767px) {
    padding-bottom: 100%; /* Adjust for a different aspect ratio on smaller screens if needed */
  }

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const CardTitle = styled.h2`
  text-align: center;
  font-family: "Copperplate";
  color: #000;
  margin-top: 10px;
  font-size: 18px;
  /* Capital letters removal */
  /* text-transform: uppercase; */
  padding-top: 10px;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const CardDescription = styled.span`
  display: block;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding: 0px !important;
  margin: 0px !important;
  p {
    padding: 0px !important;
    margin: 0px !important;
  }
`;
