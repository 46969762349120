import Helmet from "react-helmet";

const Meta = () => {
  return (
    <Helmet>
      <title>ld2art - View image</title>
      <meta name="thumbnail" content="URL/showcase.jpg" />
      <meta name="robots" content="index, follow" />
      <meta name="description" content="" />
      <meta name="author" content="ld2art" />
      <meta name="keywords" content="" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content="URL/contact" />
      <meta name="twitter:title" content={`ld2art - View image`} />
      <meta name="twitter:description" content="" />
      <meta name="twitter:image" content="URL/showcase.jpg" />

      <meta property="og:url" content="URL/contact" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={`ld2art - View image`} />
      <meta property="og:description" content="" />
      <meta property="og:image" content="URL/showcase.jpg" />
      <link rel="canonical" href="URL/contact" />
    </Helmet>
  );
};

export default Meta;
