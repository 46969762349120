import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const ListWrapper = styled.div``;

export const InnerListWrapper = styled.div``;

export const SortingWrapper = styled.div`
  margin-top: 50px;
`;

export const SortRow = styled(Row)``;

export const SortCol = styled(Col)`
  &.align-right {
    text-align: right;
  }
`;

export const ResultsLabel = styled.h5`
  font-family: "Copperplate";
  font-size: 18px;
`;

export const ListTitle = styled.h2`
  text-align: center;
  font-family: "Copperplate";
  font-size: 30px;
  /* Capital letters removal */
  /* text-transform: uppercase; */
  @media (max-width: 767px) {
    font-size: 25px;
  }
`;

export const TextWrapper = styled.div`
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
`;

export const ItemsWrapper = styled.div`
  &.single-row {
    margin-top: 25px;
  }
`;

export const ItemsRow = styled(Row)``;

export const ItemCol = styled(Col)``;
