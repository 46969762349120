import { ListItem } from "../../../types";
import { ContentWrapper, InnerContentWrapper } from "./styled";
import List from "../../../components/list";

type IntroductionProps = {
  drawings: ListItem[];
  paintings: ListItem[];
};

const Content = ({ drawings, paintings }: IntroductionProps) => {
  let allArtwork = drawings.concat(paintings);

  const sortedArray = allArtwork.sort((a, b) => a.order - b.order);

  return (
    <ContentWrapper>
      <InnerContentWrapper>
        <List
          title="Drawing & painting"
          items={sortedArray}
          rows="multiple"
          single={true}
        />
      </InnerContentWrapper>
    </ContentWrapper>
  );
};

export default Content;
