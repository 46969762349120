import { useCallback, useState } from "react";

const useFetchPaintings = () => {
  const [paintings, setPaintings] = useState([] as any[]);
  const fetchPaintings = useCallback(async () => {
    try {
      const request = await fetch(
        "https://ld2art-backend-00333e89e8d8.herokuapp.com/api/paintings?populate=*",
        { method: "GET" }
      );
      if (request.ok) {
        let allArtworks: any[] = [];
        const { data } = await request.json();
        data?.forEach((item: any) => {
          allArtworks.push({
            imageUrl: item?.attributes?.Image?.data?.attributes?.url,
            title: item?.attributes?.Title,
            description: item?.attributes?.Description,
            order: item?.attributes?.Order,
          });
        });

        setPaintings(allArtworks);
      }
    } catch (e: any) {
      throw new Error(e);
    }
  }, []);
  return { fetchPaintings, paintings };
};

export default useFetchPaintings;
