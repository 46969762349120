import MasonryView from "../../../components/masonry-view";
import { ListItem } from "../../../types";
import { ContentWrapper } from "./styled";

type IntroductionProps = {
  photographies: ListItem[];
};

const Content = ({ photographies }: IntroductionProps) => {
  return (
    <ContentWrapper>
      <MasonryView
        title="Photography"
        items={photographies.slice(0, 2)}
        columnRange={[1, 2]}
      />
      <MasonryView items={photographies.slice(2, 13)} columnRange={[2, 3]} />
      <MasonryView items={photographies.slice(13, 16)} columnRange={[3, 3]} />
      <MasonryView items={photographies.slice(16, 22)} columnRange={[3, 3]} />
      <MasonryView items={photographies.slice(22, 27)} columnRange={[3, 3]} />
    </ContentWrapper>
  );
};

export default Content;
