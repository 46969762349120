import { StyledTitle } from "./styled";

type TitleProps = {
  size?: "regular" | "large";
  children: any;
};

const Title = ({ size = "regular", children }: TitleProps) => {
  return <StyledTitle className={`size-${size}`}>{children}</StyledTitle>;
};

export default Title;
