import styled from "@emotion/styled";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

export const NavigationWrapper = styled.div`
  position: fixed;
  width: 100%;
  transition: 0.5s;
  z-index: 20;
  background-color: #fff;
`;

export const InnerNavigationWrapper = styled.div`
  width: 100%;
  max-width: 87.5rem;
  padding: 0.3125rem 0.9rem;
  margin: 0 auto;
`;

export const NavbarWrapper = styled(Navbar)`
  background-color: transparent !important;
  a {
    padding-left: 0px;
  }
`;

export const NavbarBrand = styled.h2`
  text-transform: uppercase;
  font-family: Copperplate;
`;

export const NavbarToggle = styled(Navbar.Toggle)`
  border: none;
  padding: 0rem;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  padding-right: 7.5px;
`;

export const ToggleIcon = styled.img`
  width: 22.5px;
`;

export const Toggle = styled.span`
  height: 1.5625rem;
  display: block;
  color: #3b3b3b;
  width: 1.25rem;
  > i {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.75rem;
  }
`;

export const NavbarCollapse = styled(Navbar.Collapse)``;

export const NavWrapper = styled(Nav)``;

export const NavLink = styled(Link)`
  font-family: "Copperplate", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 0px 10px;
  color: #3b3b3b !important;
  transition: 1s;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const NavDropdownWrapper = styled(NavDropdown)`
  font-family: "Copperplate", sans-serif;
  margin: 4px !important;
  font-size: 16px;
  font-weight: 500;
  color: #3b3b3b !important;
  transition: 1s;
  text-decoration: none;
  .dropdown-menu {
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0px;
    border-color: #000;
  }
`;

export const NavDropdownItem = styled(NavDropdown.Item)`
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  padding: 7.5px 10px !important;
  &:hover {
    background-color: #000;
    color: #fff;
  }
`;

export const NavText = styled.span`
  line-height: 3.1rem;
`;
