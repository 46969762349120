import { useCallback, useState } from "react";

const useFetchFilms = () => {
  const [films, setFilms] = useState([] as any[]);
  const fetchFilms = useCallback(async () => {
    try {
      const request = await fetch(
        "https://ld2art-backend-00333e89e8d8.herokuapp.com/api/films?populate=*",
        { method: "GET" }
      );
      if (request.ok) {
        let allFilms: any[] = [];
        const { data } = await request.json();
        data?.forEach((item: any) => {
          allFilms.push({
            imageUrl: item?.attributes?.Cover?.data?.attributes?.url,
            title: item?.attributes?.Title,
            description: item?.attributes?.Text,
          });
        });

        setFilms(allFilms);
      }
    } catch (e: any) {
      throw new Error(e);
    }
  }, []);
  return { fetchFilms, films };
};

export default useFetchFilms;
