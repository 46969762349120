import styled from "@emotion/styled";

export const StyledButton = styled.button`
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 25px;
  font-family: "Copperplate";
  border: 2px solid #000;
  transition: 0.5s;
  z-index: 2;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color: #000;
  }
  :disabled {
    background-color: #fff;
    color: #000;
    &:hover {
      cursor: not-allowed;
    }
  }
`;
