import styled from "@emotion/styled";

export const ContentWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;
  max-width: 850px;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding-top: 60px;
  }
`;

export const InnerContentWrapper = styled.div`
  padding: 20px;
  h2 {
    &:not(:first-of-type) {
      font-size: 24px !important;
    }
  }
`;

export const Field = styled.div`
  margin-bottom: 30px;
`;
