import { useState } from "react";
import Text from "../text";
import {
  CardDescription,
  CardImage,
  CardImageWrapper,
  CardTitle,
  CardWrapper,
  InnerCardWrapper,
  StyledLink,
} from "./styled";

type CardProps = {
  imageUrl: string;
  hoverUrl: string;
  title?: string;
  linkUrl?: string;
  description?: string;
  masonry?: boolean;
  border?: boolean;
};

const Card = ({
  imageUrl,
  hoverUrl,
  title,
  linkUrl,
  description,
  masonry,
}: CardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <CardWrapper className={`${masonry ? "masonry-view" : ""}`}>
      {linkUrl ? (
        <StyledLink to={linkUrl}>
          <InnerCardWrapper>
            <CardImageWrapper>
              <CardImage
                src={isHovered && hoverUrl ? hoverUrl : imageUrl}
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
              />
            </CardImageWrapper>
            {title && <CardTitle>{title}</CardTitle>}
          </InnerCardWrapper>
        </StyledLink>
      ) : (
        <InnerCardWrapper>
          <CardImageWrapper>
            <CardImage src={imageUrl} />
          </CardImageWrapper>
          {title && <CardTitle>{title}</CardTitle>}
          {description && (
            <CardDescription>
              <Text>{description}</Text>
            </CardDescription>
          )}
        </InnerCardWrapper>
      )}
    </CardWrapper>
  );
};

export default Card;
