import { useEffect } from "react";
import Footer from "../../components/footer";
import Navigation from "../../components/navigation";
import Showcase from "./showcase";
import useFetchContactDetails from "../../hooks/useFetchContactDetails";
import Meta from "./meta";

const Contact = () => {
  const { fetchData, details } = useFetchContactDetails();
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Meta />
      <Navigation />
      <Showcase details={details} />
      <Footer />
    </>
  );
};

export default Contact;
