import { useParams } from "react-router-dom";
import Button from "../../../components/button";
import ImageView from "../../../components/image-view";
import Text from "../../../components/text";
import Title from "../../../components/title";
import {
  ButtonLink,
  ButtonWrapper,
  DetailCol,
  DetailsRow,
  DetailsWrapper,
  InnerDetailsWrapper,
} from "./styled";
import useFetchArtwork from "../../../hooks/useFetchArtwork";
import { useEffect } from "react";

const Details = () => {
  const { productId } = useParams();
  const { artwork, fetchArtwork } = useFetchArtwork();

  const EMAIL_TEMPLATE = `mailto:lars@ditlev.no?subject=Inquiry regarding painting ${artwork?.reference}&body=Hi, I'm interested in this painting.`;

  useEffect(() => {
    if (productId) {
      fetchArtwork(productId);
    }
  }, [fetchArtwork, productId]);
  return (
    <DetailsWrapper>
      <InnerDetailsWrapper>
        <DetailsRow>
          <DetailCol md={6} className="thumbnails">
            <ImageView images={artwork?.images} />
          </DetailCol>
          <DetailCol md={6} className="details">
            <Title>{artwork?.title}</Title>

            {artwork?.details?.map((detail) => (
              <span>
                {detail.children?.map((child: any) => (
                  <Text>{child?.text}</Text>
                ))}
              </span>
            ))}

            <ButtonWrapper>
              {artwork?.sold ? (
                <Button disabled={true}>Painting sold</Button>
              ) : (
                <ButtonLink to={EMAIL_TEMPLATE}>
                  <Button>Request price</Button>
                </ButtonLink>
              )}
            </ButtonWrapper>
          </DetailCol>
        </DetailsRow>
      </InnerDetailsWrapper>
    </DetailsWrapper>
  );
};

export default Details;
