import { useCallback, useState } from "react";

const useFetchDrawings = () => {
  const [drawings, setDrawings] = useState([] as any[]);
  const fetchDrawings = useCallback(async () => {
    try {
      const request = await fetch(
        "https://ld2art-backend-00333e89e8d8.herokuapp.com/api/drawings?populate=*",
        { method: "GET" }
      );
      if (request.ok) {
        let allArtworks: any[] = [];
        const { data } = await request.json();
        data?.forEach((item: any) => {
          allArtworks.push({
            imageUrl: item?.attributes?.Image?.data?.attributes?.url,
            title: item?.attributes?.Title,
            description: item?.attributes?.Description,
            order: item?.attributes?.Order,
          });
        });

        setDrawings(allArtworks);
      }
    } catch (e: any) {
      throw new Error(e);
    }
  }, []);
  return { fetchDrawings, drawings };
};

export default useFetchDrawings;
