import List from "../../../components/list";
import {
  ContactCol,
  ContactRow,
  ContactWrapper,
  ExploreWrapper,
  Field,
  Illustration,
  IllustrationText,
  IllustrationWrapper,
  IllustrationsWrapper,
  InnerShowcaseWrapper,
  ShowcaseWrapper,
} from "./styled";

import Title from "../../../components/title";
import Text from "../../../components/text";
import Button from "../../../components/button";
import { ContactDetails } from "../../../types";
import { Link } from "react-router-dom";

type ShowcaseProps = {
  details: ContactDetails;
};

const Showcase = ({ details }: ShowcaseProps) => {
  return (
    <ShowcaseWrapper>
      <InnerShowcaseWrapper>
        <ContactWrapper>
          <ContactRow>
            <ContactCol className="details">
              <Title size="regular">{details?.title ?? ""}</Title>
              {details?.description?.map((block: any) => (
                <Field>
                  {block?.children?.map((child: any) => (
                    <Text>{child.text}</Text>
                  ))}
                </Field>
              ))}
              <Link to={`mailto:${details?.email}`}>
                <Button>{details?.button ?? ""}</Button>
              </Link>
            </ContactCol>
            <ContactCol className="thumbnail">
              <IllustrationsWrapper>
                <IllustrationWrapper>
                  <Illustration
                    alt={details?.illustrationTopLeft?.alt}
                    src={details?.illustrationTopLeft?.url}
                  />
                  <IllustrationText>Lars, 1985</IllustrationText>
                </IllustrationWrapper>
                <IllustrationWrapper>
                  <Illustration
                    alt={details?.illustrationTopRight?.alt}
                    src={details?.illustrationTopRight?.url}
                  />
                </IllustrationWrapper>
              </IllustrationsWrapper>
            </ContactCol>
          </ContactRow>
        </ContactWrapper>
        <ExploreWrapper>
          <List
            items={details?.gallery}
            rows="single"
            title={details?.subtitle}
            columns={3}
            single={true}
          />
        </ExploreWrapper>
      </InnerShowcaseWrapper>
    </ShowcaseWrapper>
  );
};

export default Showcase;
