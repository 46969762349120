import { Link } from "react-router-dom";
import Button from "../../../components/button";
import Text from "../../../components/text";
import Title from "../../../components/title";
import {
  ContentWrapper,
  InnerContentWrapper,
  NotificationWrapper,
} from "./styled";

const Index = () => {
  return (
    <ContentWrapper>
      <InnerContentWrapper>
        <NotificationWrapper>
          <Title>Page not found</Title>
          <Text>The page you're looking for doesn't seem to exist.</Text>
          <Link to="/">
            <Button>Go to frontpage</Button>
          </Link>
        </NotificationWrapper>
      </InnerContentWrapper>
    </ContentWrapper>
  );
};

export default Index;
