import styled from "@emotion/styled";

export const ContentWrapper = styled.div`
  height: 100vh;
  max-width: 850px;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding-top: 60px;
  }
`;

export const InnerContentWrapper = styled.div`
  padding: 20px;

  h2 {
    &:not(:first-of-type) {
      font-size: 24px !important;
    }
  }
`;

export const NotificationWrapper = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
