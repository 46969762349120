import styled from "@emotion/styled";

export const ShowcaseWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

export const InnerShowcaseWrapper = styled.div`
  padding: 20px;
`;

export const ContactWrapper = styled.div`
  padding-top: 100px;
  @media (max-width: 767px) {
    padding-top: 60px;
  }
`;

export const IllustrationWrapper = styled.div`
  width: 50%;
  object-fit: contain;
  &:first-of-type {
    width: 37.5%;
  }
  &:last-of-type {
    width: 62.5%;
  }
`;

export const Illustration = styled.img`
  width: 100%;
`;

export const IllustrationText = styled.span`
  font-family: "Times";
  display: block;
  text-align: center;
  font-size: 16px;
`;

export const ContactRow = styled.div`
  display: flex;
  gap: 100px;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const ContactCol = styled.div`
  @media (max-width: 900px) {
    &.details {
      order: 2;
    }
  }
`;

export const ExploreWrapper = styled.div`
  margin-top: 100px;
`;

export const IllustrationsWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding-right: 20px;
  align-items: center;
`;

export const Field = styled.div`
  margin-bottom: 30px;
`;
