import styled from "@emotion/styled";

export const ListWrapper = styled.div`
  &:first-of-type {
    padding: 20px 20px 0 20px;
  }
`;

export const InnerListWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

export const Thumbnail = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

export const ListTitle = styled.h2`
  text-align: center;
  font-family: "Copperplate";
  font-size: 30px;
  /* Capital letters removal */
  /* text-transform: uppercase; */
  margin-bottom: 25px;
`;

export const MasonryWrapper = styled.div`
  .container {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const TextWrapper = styled.div`
  max-width: 650px;
  text-align: center;
  margin: 0 auto;
`;

export const Row = styled.div`
  display: flex;
`;

export const Col = styled.div`
  display: flex;
  width: 100%;
  &.order-1 {
    width: 200px;
  }
`;

export const PictureWrapper = styled.span`
  position: relative;
  display: flex;
  &.info-wrap {
    padding-bottom: 70px;
  }
  @media (max-width: 850px) {
    &.info-wrap {
      padding-bottom: 0px;
    }
  }
`;

export const DetailsWrapper = styled.div`
  text-align: center;
  display: flex;
  margin-top: auto;
  background-color: #fff;
  padding: 5px 0px 10px 0px;
  bottom: 0px;
  z-index: 100;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: block;
  @media (max-width: 850px) {
    display: none;
  }
`;

export const PictureTitle = styled.h2`
  text-align: center;
  font-family: "Copperplate";
  color: #000;
  margin-top: 10px;
  font-size: 20px;
  height: 30px;
  margin-bottom: 0px;
  text-transform: uppercase;
  display: block;
  align-items: middle;
`;

export const PictureDescription = styled.span`
  margin: 0 auto;
  width: 100%;
  text-align: center;
  display: block;
  align-items: middle;
`;
