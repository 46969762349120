import Helmet from "react-helmet";

const Meta = () => {
  return (
    <Helmet>
      <title>LD2ART - Home</title>
      <meta name="thumbnail" content="URL/showcase.jpg" />
      <meta name="robots" content="index, follow" />
      <meta
        name="description"
        content="Lars paints unique artworks inspired by abstract artists such as Gerhard Richter, Mark Rothko, Jackson Pollock and Jakob Weidemann."
      />
      <meta name="author" content="LD2ART" />
      <meta name="keywords" content="" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content="URL" />
      <meta name="twitter:title" content={`LD2ART - Home`} />
      <meta
        name="twitter:description"
        content="Lars paints unique artworks inspired by abstract artists such as Gerhard Richter, Mark Rothko, Jackson Pollock and Jakob Weidemann."
      />
      <meta name="twitter:image" content="URL/showcase.jpg" />

      <meta property="og:url" content="URL" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={`LD2ART - Home`} />
      <meta
        property="og:description"
        content="Lars paints unique artworks inspired by abstract artists such as Gerhard Richter, Mark Rothko, Jackson Pollock and Jakob Weidemann."
      />
      <meta property="og:image" content="URL/showcase.jpg" />
      <link rel="canonical" href="URL" />
    </Helmet>
  );
};

export default Meta;
