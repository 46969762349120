import Slider from "../../../components/slider";
import { SliderItem } from "../../../types";
import { InnerIntroductionWrapper, IntroductionWrapper } from "./styled";

type IntroductionProps = {
  sliderImages: SliderItem[];
};
const Introduction = ({ sliderImages }: IntroductionProps) => {
  return (
    <IntroductionWrapper>
      <InnerIntroductionWrapper>
        <Slider items={sliderImages} />
      </InnerIntroductionWrapper>
    </IntroductionWrapper>
  );
};

export default Introduction;
