import { useEffect } from "react";
import Footer from "../../components/footer";
import Navigation from "../../components/navigation";
import Categories from "./categories";
import Introduction from "./introduction";
import useFetchFrontpageDetails from "../../hooks/useFetchFrontpageDetails";
import Meta from "./meta";

const Index = () => {
  const { fetchData, details } = useFetchFrontpageDetails();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Meta />
      <Navigation />
      <Introduction sliderImages={details?.slider} />
      <Categories title={details?.subtitle} features={details?.features} />
      <Footer />
    </>
  );
};

export default Index;
