import { JustifiedGrid } from "@egjs/react-grid";
import {
  DetailsWrapper,
  InnerListWrapper,
  ListTitle,
  ListWrapper,
  MasonryWrapper,
  PictureDescription,
  PictureTitle,
  PictureWrapper,
  TextWrapper,
  Thumbnail,
} from "./styled";
import Text from "../text";

type MasonryViewProps = {
  items: any[];
  title?: string;
  text?: string;
  columnRange?: number[];
};

export const MasonryView = ({
  items,
  title,
  text,
  columnRange = [3, 3],
}: MasonryViewProps) => {
  return (
    <ListWrapper>
      <InnerListWrapper>
        {title && <ListTitle>{title}</ListTitle>}
        {text && (
          <TextWrapper>
            <Text>{text}</Text>
          </TextWrapper>
        )}
        <MasonryWrapper>
          <JustifiedGrid
            className="container"
            gap={10}
            defaultDirection={"end"}
            columnRange={columnRange}
            sizeRange={[500, 500]}
            isCroppedSize={false}
            displayedRow={-1}
          >
            {items.map((item, index) => (
              <PictureWrapper
                className={`${item?.title && item.description && "info-wrap"}`}
              >
                <Thumbnail src={item.imageUrl} className={`image-${index}`} />
                {item?.title && item?.description && (
                  <DetailsWrapper>
                    {item?.title && <PictureTitle>{item.title}</PictureTitle>}
                    {item?.description && (
                      <PictureDescription>
                        {item.description}
                      </PictureDescription>
                    )}
                  </DetailsWrapper>
                )}
              </PictureWrapper>
            ))}
          </JustifiedGrid>
        </MasonryWrapper>
      </InnerListWrapper>
    </ListWrapper>
  );
};

export default MasonryView;
