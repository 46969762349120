import { useEffect } from "react";
import Footer from "../../components/footer";
import Navigation from "../../components/navigation";
import Meta from "./meta";
import useFetchDrawings from "../../hooks/useFetchDrawings";
import Content from "./content";
import useFetchPaintings from "../../hooks/useFetchPaintings";

const Index = () => {
  const { fetchDrawings, drawings } = useFetchDrawings();
  const { fetchPaintings, paintings } = useFetchPaintings();

  useEffect(() => {
    fetchDrawings();
    fetchPaintings();
  }, [fetchDrawings, fetchPaintings]);

  return (
    <>
      <Meta />
      <Navigation />
      <Content drawings={drawings} paintings={paintings} />
      <Footer />
    </>
  );
};

export default Index;
