import { useCallback, useState } from "react";

const useFetchArtworks = () => {
  const [artworks, setArtworks] = useState([] as any[]);

  const fetchArtworks = useCallback(async (filter: string | undefined) => {
    try {
      const query =
        filter === "all"
          ? "artworks?sort=Title&populate=*&pagination[pageSize]=50"
          : `artworks?sort=Title&populate=*&pagination[pageSize]=50&filters[Category][$eq]=${filter}`;
      const request = await fetch(
        `https://ld2art-backend-00333e89e8d8.herokuapp.com/api/${query}`,
        { method: "GET" }
      );
      if (request.ok) {
        let allArtworks: any[] = [];
        const { data } = await request.json();
        data?.forEach((item: any) => {
          allArtworks.push({
            imageUrl: item?.attributes?.Images?.data[0]?.attributes?.url,
            hoverUrl: item?.attributes?.Images?.data[1]?.attributes?.url,
            title: item?.attributes?.Title,
            link: `/gallery/${item?.id}`,
          });
        });

        setArtworks(allArtworks);
      }
    } catch (e: any) {
      throw new Error(e);
    }
  }, []);

  return { fetchArtworks, artworks };
};

export default useFetchArtworks;
