import styled from "@emotion/styled";

export const ContentWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;
  max-width: 1400px;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding-top: 60px;
  }
`;

export const InnerContentWrapper = styled.div`
  padding: 20px;
`;

export const MasonryWrapper = styled.div`
  margin-top: 25px;
  .container {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const Thumbnail = styled.img`
  width: 100%;
  object-fit: cover;
`;
