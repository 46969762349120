import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const FooterWrapper = styled.div`
  max-width: 1450px;
  margin: 0 auto;
`;

export const InnerWrapper = styled.div`
  padding: 20px;
`;

export const FooterRow = styled(Row)``;

export const FooterCol = styled(Col)`
  &.align-left {
    text-align: left;
  }
  &.align-center {
    text-align: center;
  }
  &.align-right {
    text-align: right;
  }
  @media (max-width: 767px) {
    text-align: center !important;
    &.align-center {
      padding-bottom: 20px;
      margin-top: -10px !important;
    }
  }
`;

export const FooterTitle = styled.h3`
  text-transform: uppercase;
  font-family: Copperplate;
  font-size: 24px;
  display: block;
  padding: 0px !important;
  margin: 0px !important;
`;

export const LinksWrapper = styled.div``;

export const FooterButton = styled.button`
  background: none;
  border: none;
  font-family: "Copperplate", sans-serif;
  font-size: 16px;
  color: #3b3b3b !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const FooterLink = styled(Link)`
  font-family: "Copperplate", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 0px 10px;
  color: #3b3b3b !important;
  transition: 1s;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const SocialIcon = styled.img`
  height: 20px;
  margin: 0px 7.5px;
`;

export const IconLink = styled(Link)`
  font-family: "Copperplate", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 0px 10px;
  color: #3b3b3b !important;
  text-decoration: none;
`;
