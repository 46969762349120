import { useEffect } from "react";
import Footer from "../../components/footer";
import Navigation from "../../components/navigation";
import Meta from "./meta";
import useFetchPhotographies from "../../hooks/useFetchPhotographies";
import Content from "./content";

const Index = () => {
  const { fetchPhotographies, photographies } = useFetchPhotographies();

  useEffect(() => {
    fetchPhotographies();
  }, [fetchPhotographies]);

  return (
    <>
      <Meta />
      <Navigation />
      <Content photographies={photographies} />
      <Footer />
    </>
  );
};

export default Index;
