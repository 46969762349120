import { useCallback, useState } from "react";

const useFetchGalleryDetails = () => {
  const [details, setDetails] = useState({ title: "", description: "" });

  const fetchData = useCallback(async () => {
    try {
      const request = await fetch(
        "https://ld2art-backend-00333e89e8d8.herokuapp.com/api/gallery?populate=*",
        { method: "GET" }
      );
      if (request.ok) {
        const { data } = await request.json();
        setDetails({
          title: data?.attributes?.Title,
          description: data?.attributes?.Description,
        });
      }
    } catch (e: any) {
      throw new Error(e);
    }
  }, []);

  return { fetchData, details };
};

export default useFetchGalleryDetails;
