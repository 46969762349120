import { useEffect, useState } from "react";
import {
  HeroImage,
  ImageViewWrapper,
  SelectionWrapper,
  SmallThumbnail,
  SmallThumbnailContainer,
} from "./styled";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

type ImageViewProps = {
  images: any[];
};

const ImageView = ({ images }: ImageViewProps) => {
  const [activeImage, setActiveImage] = useState<null | any>(null);

  useEffect(() => {
    if (!activeImage?.imageUrl) {
      setActiveImage(images[0]);
    }
  }, [activeImage, images]);

  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: "#photo-gallery",
      children: "a",
      pswpModule: () => import("photoswipe"),
    });
    lightbox.init();
  });

  return (
    <ImageViewWrapper id="photo-gallery">
      {activeImage?.imageUrl && (
        <a
          className="hero"
          href={activeImage?.imageUrl}
          data-pswp-width="1875"
          data-pswp-height="2500"
        >
          <HeroImage alt={activeImage?.altText} src={activeImage?.imageUrl} />
        </a>
      )}
      <SelectionWrapper>
        {images.map((image) => (
          <SmallThumbnailContainer
            href={image?.imageUrl}
            data-pswp-width="1875"
            data-pswp-height="2500"
          >
            <SmallThumbnail alt={image?.altText} src={image?.imageUrl} />
          </SmallThumbnailContainer>
        ))}
      </SelectionWrapper>
    </ImageViewWrapper>
  );
};

export default ImageView;
