import { useCallback, useState } from "react";

const useFetchContactDetails = () => {
  const [details, setDetails] = useState({
    title: "",
    description: [],
    button: "",
    subtitle: "",
    illustrationTopLeft: { alt: "", url: "" },
    illustrationTopRight: { alt: "", url: "" },
    gallery: [] as any[],
    email: "",
  });
  const fetchData = useCallback(async () => {
    try {
      const request = await fetch(
        "https://ld2art-backend-00333e89e8d8.herokuapp.com/api/contact?populate=*",
        { method: "GET" }
      );
      if (request.ok) {
        let images: any[] = [];
        const { data } = await request.json();
        data.attributes?.Gallery.data?.forEach((image: any) => {
          images.push({
            altText: image?.attributes?.alternativeText,
            imageUrl: image?.attributes?.url,
          });
        });
        setDetails({
          title: data?.attributes?.MainTitle,
          description: data?.attributes?.details,
          button: data?.attributes?.Button,
          illustrationTopLeft: {
            alt: data?.attributes?.IllustrationTopLeft.data?.attributes
              ?.alternativeText,
            url: data?.attributes?.IllustrationTopLeft.data?.attributes?.url,
          },
          illustrationTopRight: {
            alt: data?.attributes?.IllustrationTopRight.data?.attributes
              ?.alternativeText,
            url: data?.attributes?.IllustrationTopRight.data?.attributes?.url,
          },
          gallery: images,
          subtitle: data?.attributes?.GalleryTitle,
          email: data?.attributes?.ContactEmail,
        });
      }
    } catch (e: any) {
      throw new Error(e);
    }
  }, []);
  return { fetchData, details };
};

export default useFetchContactDetails;
