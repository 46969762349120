// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pswp img {
  object-fit: contain !important;
  width: 100%;
  height: 100%;
}

#CookiebotWidget{
  box-shadow: none !important;
}

#CookiebotWidget:not(.CookiebotWidget-inactive) {
  box-shadow: none !important;
}

#CookiebotWidget>button>svg{
  opacity: 0 !important;
}
/*
#CookiebotWidget>.CookiebotWidget-logo{
  opacity: 0 !important;
}
  */

/*
#CookiebotWidget:not(.CookiebotWidget-inactive) {
  opacity: 0 !important;
}
*/`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,8BAA8B;EAC9B,WAAW;EACX,YAAY;AACd;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;AACvB;AACA;;;;GAIG;;AAEH;;;;CAIC","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.pswp img {\n  object-fit: contain !important;\n  width: 100%;\n  height: 100%;\n}\n\n#CookiebotWidget{\n  box-shadow: none !important;\n}\n\n#CookiebotWidget:not(.CookiebotWidget-inactive) {\n  box-shadow: none !important;\n}\n\n#CookiebotWidget>button>svg{\n  opacity: 0 !important;\n}\n/*\n#CookiebotWidget>.CookiebotWidget-logo{\n  opacity: 0 !important;\n}\n  */\n\n/*\n#CookiebotWidget:not(.CookiebotWidget-inactive) {\n  opacity: 0 !important;\n}\n*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
