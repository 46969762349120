import { useCallback, useState } from "react";

const useTermsAndConditions = () => {
  const [data, setData] = useState({ title: "", content: [] });

  const fetchData = useCallback(async () => {
    try {
      const request = await fetch(
        "https://ld2art-backend-00333e89e8d8.herokuapp.com/api/terms-and-condition",
        { method: "GET" }
      );
      if (request.ok) {
        const { data } = await request.json();
        setData({
          title: data?.attributes?.Title,
          content: data?.attributes?.Text,
        });
      }
    } catch (e: any) {
      throw new Error(e);
    }
  }, []);

  return { data, fetchData };
};

export default useTermsAndConditions;
