import styled from "@emotion/styled";

export const OverviewWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;
  max-width: 1400px;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding-top: 60px;
  }
`;

export const InnerOverviewWrapper = styled.div`
  padding: 20px;
  margin: 0 auto;
`;

export const CoverThumbnail = styled.img`
  margin: 0 auto;
  display: block;
  width: 100%;
`;

export const DetailsWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-top: 25px;
`;

export const Field = styled.div`
  margin-bottom: 30px;
`;

export const DescriptionWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;
