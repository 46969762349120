import {
  FooterButton,
  FooterCol,
  FooterLink,
  FooterRow,
  FooterTitle,
  FooterWrapper,
  IconLink,
  InnerWrapper,
  LinksWrapper,
  SocialIcon,
} from "./styled";
import instagramIcon from "../../assets/img/instagram-icon.svg";

interface FooterProps {
  id?: string;
}

const Footer = ({ id }: FooterProps) => {
  const handleCookies = () => {
    const cookiebotButton = document.querySelector(
      ".CookiebotWidget-logo"
    ) as HTMLButtonElement;

    if (cookiebotButton) {
      cookiebotButton.click();
    }
  };
  return (
    <FooterWrapper id={id}>
      <InnerWrapper>
        <FooterRow>
          <FooterCol md={5} className="align-left my-auto">
            <FooterLink to="/">
              <FooterTitle>ld2art</FooterTitle>
            </FooterLink>
          </FooterCol>
          <FooterCol md={2} className="align-center my-auto">
            <IconLink to="https://www.instagram.com/ld2art/">
              Follow me
              <SocialIcon src={instagramIcon} />
            </IconLink>
          </FooterCol>
          <FooterCol md={5} className="align-right my-auto">
            <LinksWrapper>
              <FooterButton onClick={handleCookies}>
                Cookie settings
              </FooterButton>
              <FooterLink to="/privacy-policy">Privacy policy</FooterLink>
              <FooterLink to="/terms-and-conditions">Terms</FooterLink>
            </LinksWrapper>
          </FooterCol>
        </FooterRow>
      </InnerWrapper>
    </FooterWrapper>
  );
};

export default Footer;
