import styled from "@emotion/styled";

export const IntroductionWrapper = styled.div`
  max-width: 1450px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 25px;
  @media (max-width: 767px) {
    padding-top: 60px;
  }
`;

export const InnerIntroductionWrapper = styled.div`
  padding: 20px;
`;
