import { StyledButton } from "./styled";

type ButtonProps = {
  children: any;
  disabled?: boolean;
};

const Button = ({ children, disabled = false }: ButtonProps) => {
  return <StyledButton disabled={disabled}>{children}</StyledButton>;
};

export default Button;
