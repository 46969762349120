import { useCallback, useState } from "react";

const useFetchArtwork = () => {
  const [artwork, setArtwork] = useState({
    title: "",
    details: [] as any[],
    sold: false,
    reference: "",
    images: [] as any[],
  });

  const fetchArtwork = useCallback(async (id: string) => {
    try {
      const request = await fetch(
        `https://ld2art-backend-00333e89e8d8.herokuapp.com/api/artworks/${id}?populate=*`,
        { method: "GET" }
      );
      if (request.ok) {
        const { data } = await request.json();
        const images: any[] = [];
        data?.attributes?.Images?.data?.forEach((image: any) => {
          images.push({
            imageUrl: image?.attributes?.url,
            altText: image?.attributes?.alternativeText,
          });
        });
        setArtwork({
          title: data?.attributes?.Title,
          details: data?.attributes?.Details,
          reference: data?.attributes?.Reference,
          sold: data?.attributes?.Sold,
          images: images,
        });
      }
    } catch (e: any) {
      throw new Error(e);
    }
  }, []);

  return { artwork, fetchArtwork };
};

export default useFetchArtwork;
