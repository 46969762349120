import { StyledText } from "./styled";

type TextProps = {
  children: any;
};

const Text = ({ children }: TextProps) => {
  return <StyledText>{children}</StyledText>;
};

export default Text;
