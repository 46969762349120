import List from "../../../components/list";
import { CategoriesWrapper, InnerCategoriesWrapper } from "./styled";

type CategoriesProps = {
  title: string;
  features: any[];
};

const Categories = ({ title, features }: CategoriesProps) => {
  return (
    <CategoriesWrapper>
      <InnerCategoriesWrapper>
        <List
          title={title}
          text="Lars paints unique artworks inspired by abstract artists such as Gerhard Richter, Mark Rothko, Jackson Pollock and Norwegian artist Jakob Weidemann. He can also provide more pop-art inspired subject matter.
Clients vary from private individuals to office spaces of small and large businesses, hotels, stores, public buildings, etc. Paintings can be specially ordered according to your own wishes and needs.
The customer can collaborate with Lars on both size and choice of colors."
          rows="single"
          items={features}
          single={true}
        />
      </InnerCategoriesWrapper>
    </CategoriesWrapper>
  );
};

export default Categories;
