import styled from "@emotion/styled";

export const OverviewWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;
  max-width: 1400px;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding-top: 60px;
  }
`;

export const InnerOverviewWrapper = styled.div`
  padding: 20px;
`;
