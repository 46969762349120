import {
  StyledDropdown,
  StyledDropdownMenu,
  StyledDropdownToggle,
  StyledDropdownItem,
} from "./styled";

type DropdownItem = {
  title: string;
  onClick: () => void;
};

type DropdownProps = {
  items: DropdownItem[];
  title: string;
};

const Dropdown = ({ title, items }: DropdownProps) => {
  return (
    <StyledDropdown>
      <StyledDropdownToggle id="">{title}</StyledDropdownToggle>
      <StyledDropdownMenu>
        {items.map((item) => (
          <StyledDropdownItem onClick={item.onClick}>
            {item.title}
          </StyledDropdownItem>
        ))}
      </StyledDropdownMenu>
    </StyledDropdown>
  );
};

export default Dropdown;
