import styled from "@emotion/styled";

export const CategoriesWrapper = styled.div`
  padding-top: 25px;
  padding-bottom: 50px;
  max-width: 1400px;
  margin: 0 auto;
`;

export const InnerCategoriesWrapper = styled.div`
  padding: 20px;
`;
