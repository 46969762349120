import { useEffect } from "react";
import Footer from "../../components/footer";
import Navigation from "../../components/navigation";
import Overview from "./overview";
import Meta from "./meta";
import useFetchFilms from "../../hooks/useFetchFilm";

const Film = () => {
  const { fetchFilms, films } = useFetchFilms();

  useEffect(() => {
    fetchFilms();
  }, [fetchFilms]);

  return (
    <>
      <Meta />
      <Navigation />
      <Overview films={films} />
      <Footer />
    </>
  );
};

export default Film;
