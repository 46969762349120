import { useCallback, useState } from "react";

const useFetchPhotographies = () => {
  const [photographies, setPhotographies] = useState([] as any[]);
  const fetchPhotographies = useCallback(async () => {
    try {
      const request = await fetch(
        "https://ld2art-backend-00333e89e8d8.herokuapp.com/api/photographies?populate=*&pagination[pageSize]=35",
        { method: "GET" }
      );
      if (request.ok) {
        let allArtworks: any[] = [];
        const { data } = await request.json();

        data?.forEach((item: any) => {
          allArtworks.push({
            imageUrl: item?.attributes?.Image?.data?.attributes?.url,
            title: item?.attributes?.Title,
            description: item?.attributes?.Description,
            order: item?.attributes?.Order,
            id: item?.id,
          });
        });

        const sortedArray = allArtworks.sort((a, b) => a.order - b.order);

        setPhotographies(sortedArray);
      }
    } catch (e: any) {
      throw new Error(e);
    }
  }, []);
  return { fetchPhotographies, photographies };
};

export default useFetchPhotographies;
