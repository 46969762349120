import { BrowserRouter, Routes as AllRoutes, Route } from "react-router-dom";
import Index from "./pages/index";
import Gallery from "./pages/gallery";
import Contact from "./pages/contact";
import Product from "./pages/product";
import NotFound from "./pages/not-found";
import Photographies from "./pages/photographies";
import Drawings from "./pages/drawings-paintings";
import Terms from "./pages/terms-and-conditions";
import PrivacyPolicy from "./pages/privacy-policy";
import Film from "./pages/film";

const Routes = () => {
  return (
    <BrowserRouter>
      <AllRoutes>
        <Route path="/" element={<Index />}></Route>
        <Route path="/gallery" element={<Gallery />} />
        <Route
          path="/earlier-works/drawing-and-painting"
          element={<Drawings />}
        />
        <Route path="/earlier-works/film" element={<Film />} />
        <Route path="/earlier-works/photography" element={<Photographies />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/gallery/:productId" element={<Product />} />
        <Route path="*" element={<NotFound />} />
      </AllRoutes>
    </BrowserRouter>
  );
};

export default Routes;
