import { useEffect } from "react";
import { ContentWrapper, Field, InnerContentWrapper } from "./styled";
import Title from "../../../components/title";
import Text from "../../../components/text";
import usePrivacyPolicy from "../../../hooks/usePrivacyPolicy";

const Content = () => {
  const { data, fetchData } = usePrivacyPolicy();
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <ContentWrapper>
      <InnerContentWrapper>
        <Title>{data?.title}</Title>
        {data?.content?.map((block: any) => (
          <>
            {block.type === "paragraph" ? (
              <Field>
                {block?.children?.map((child: any) => (
                  <Text>{child.text}</Text>
                ))}
              </Field>
            ) : (
              <>
                {block?.children?.map((child: any) => (
                  <Title>{child.text}</Title>
                ))}
              </>
            )}
          </>
        ))}
      </InnerContentWrapper>
    </ContentWrapper>
  );
};

export default Content;
