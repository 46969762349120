import styled from "@emotion/styled";
import Dropdown from "react-bootstrap/Dropdown";

export const StyledDropdown = styled(Dropdown)``;

export const StyledDropdownToggle = styled(Dropdown.Toggle)`
  background-color: #fff;
  border: none;
  color: #000;
  font-family: "Copperplate";
  padding: 0px;
  &:hover,
  &:active {
    background-color: #fff;
    color: #000;
  }
  &.show {
    background-color: #fff !important;
    color: #000;
  }
  &.btn:active {
    background-color: #fff !important;
    color: #000;
  }
`;

export const StyledDropdownMenu = styled(Dropdown.Menu)`
  font-family: "Copperplate", sans-serif;
  margin: 4px !important;
  font-size: 16px;
  font-weight: 500;
  color: #3b3b3b !important;
  transition: 1s;
  text-decoration: none;
  border: 1px solid #000 !important;
  border-radius: 0px;
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  padding: 7.5px 10px !important;
  &:hover {
    background-color: #000;
    color: #fff;
  }
`;
