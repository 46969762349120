import styled from "@emotion/styled";

export const ImageViewWrapper = styled.div`
  display: block;
`;

export const HeroImage = styled.img`
  width: 100%;
`;

export const SelectionWrapper = styled.div`
  margin-top: 4px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px; // Adjust the gap as needed
  img {
    width: 100%;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const SmallThumbnailContainer = styled.a`
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  width: 100%;
`;

export const SmallThumbnail = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
