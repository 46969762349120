import styled from "@emotion/styled";

export const StyledTitle = styled.h2`
  font-family: "Copperplate";
  font-size: 40px;
  /* Capital letters removal */
  /* text-transform: uppercase; */
  &.size-regular {
    font-size: 24px;
  }
  &.size-large {
    font-size: 42px;
  }

  @media (max-width: 767px) {
    font-size: 36px;

    &.size-regular {
      font-size: 30px;
    }
    &.size-large {
      font-size: 38px;
    }
  }
`;
