import Footer from "../../components/footer";
import Navigation from "../../components/navigation";
import Details from "./details";
import Meta from "./meta";
import { FullWrapper } from "./styled";

const Product = () => {
  return (
    <FullWrapper>
      <Meta />
      <Navigation />
      <Details />
      {/*<Suggestions />*/}
      <Footer id="productFooter" />
    </FullWrapper>
  );
};

export default Product;
