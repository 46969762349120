import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const DetailsWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 767px) {
    padding-top: 60px;
  }
`;

export const InnerDetailsWrapper = styled.div`
  padding: 20px;
`;

export const DetailsRow = styled(Row)``;

export const DetailCol = styled(Col)`
  &.details {
    padding-top: 15px !important;
  }
`;

export const ButtonLink = styled(Link)`
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  padding-top: 15px !important;

  @media (max-width: 900px) {
    background-color: #fff;
    position: fixed;
    bottom: 0px;
    width: 100%;
    right: 0px;
    left: 0px;
    padding: 10px;
    button {
      width: 100%;
    }
  }
`;
