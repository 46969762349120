import List from "../../../components/list";
import { InnerOverviewWrapper, OverviewWrapper } from "./styled";

type OverviewProps = {
  artworks: any;
  details: any;
  setFilter: any;
};

const Overview = ({ artworks, details, setFilter }: OverviewProps) => {
  const setActiveFilter = (filter: string) => {
    setFilter(filter);
  };

  const FILTERS = [
    { title: "Show all", onClick: () => setActiveFilter("all") },
    { title: "Abstract art", onClick: () => setActiveFilter("abstract") },
    {
      title: "Non-abstract art",
      onClick: () => setActiveFilter("non-abstract"),
    },
  ];

  return (
    <OverviewWrapper>
      <InnerOverviewWrapper>
        <List
          title={details?.title}
          text={details?.description}
          rows="multiple"
          items={artworks}
          single={true}
          filters={FILTERS}
        />
      </InnerOverviewWrapper>
    </OverviewWrapper>
  );
};

export default Overview;
